import { Form, Input, TextArea, Button } from 'semantic-ui-react';
import classes from './contact.module.css';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const SERVICE_ID = 'service_5ztya7b';
const TEMPLATE_ID = 'template_lgrtf1a';
const PUBLIC_KEY = 'igDZcXJUwjJxWi2i4';

function ContactPage() {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, PUBLIC_KEY).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Message Sent Successfully',
        });
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: 'error',
          title: 'Ooops, something went wrong',
          text: error.text,
        });
      }
    );
    e.target.reset();
  };

  return (
    <>
      <h1>Contact AQA</h1>
      <p>Contact us to get setup with an E2E framework!</p>
      <div className={classes['contact-form']}>
        <Form onSubmit={handleOnSubmit}>
          <Form.Field
            id="name"
            control={Input}
            label="Name"
            name="from_name"
            placeholder="Name..."
            required
          />
          <Form.Field
            id="email"
            control={Input}
            label="Email"
            name="from_email"
            placeholder="Email..."
            required
          />
          <Form.Field
            id="message"
            control={TextArea}
            label="Message"
            name="message"
            placeholder="Message...your needs"
            required
          />
          <Button type="submit" color="green">
            Submit
          </Button>
        </Form>
      </div>
    </>
  );
}

export default ContactPage;
