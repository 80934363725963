import { Link } from "react-router-dom";

const AlertsPage = () => {
  // Alert box
  const showAlert = () => {
    alert("This is a simple alert message!");
  };

  // Confirm box
  const showConfirm = () => {
    const userConfirmed = window.confirm("Are you sure you want to proceed?");
    if (userConfirmed) {
      // Handle the confirmation action here
      alert("Confirmed!");
    } else {
      // Handle the cancel action here
      alert("Cancelled!");
    }
  };

  // Prompt box
  const showPrompt = () => {
    const userInput = window.prompt("Please enter something:");
    if (userInput !== null) {
      // Handle the user's input (e.g., display it in an alert)
      alert(`You entered: ${userInput}`);
    } else {
      // Handle if the user cancels the prompt
      alert("Prompt was cancelled.");
    }
  };

  return (
    <>
      {" "}
      <Link to=".." relative="path">
        Back
      </Link>
      <h2 style={{ textDecoration: "underline" }}>Alerts Page</h2>
      <div>
        <h2>Basic JavaScript Alert</h2>
        <button onClick={showAlert} data-test-id={"show-alert"}>
          Show Alert
        </button>
      </div>
      <div>
        <h2>Confirmation Prompt Example</h2>
        <button onClick={showConfirm} data-test-id={"show-confirmation-alert"}>
          Show Confirmation Prompt
        </button>
      </div>
      <div>
        <h2>Prompt Alert Example</h2>
        <button onClick={showPrompt} data-test-id={"show-prompt-alert"}>
          Show Prompt Alert
        </button>
      </div>
    </>
  );
};

export default AlertsPage;
