import { Link } from 'react-router-dom';
import FileUpload from '../../components/FileUpload';

const UploadPage = () => {
  return (
    <>
      <Link to=".." relative="path">
        Back
      </Link>
      <h2 style={{ textDecoration: 'underline' }}>Upload Page</h2>
      <div>
        <FileUpload />
      </div>
    </>
  );
};

export default UploadPage;
