import { Link } from 'react-router-dom';

function PlaygroundPage() {
  const pages = [
    { to: 'attributes', text: 'Attributes Page' },
    { to: 'alerts', text: 'Alerts Page' },
    { to: 'buttons', text: 'Buttons Page' },
    { to: 'checkbox', text: 'Checkbox Page' },
    { to: 'downloads', text: 'Downloads Page' },
    { to: 'dropdowns', text: 'Dropdowns Page' },
    { to: 'form', text: 'Form Page' },
    { to: 'iframe', text: 'iFrame Page' },
    { to: 'radio', text: 'Radio Page' },
    { to: 'scroll', text: 'Scroll Page' },
    { to: 'table', text: 'Table Page' },
    { to: 'text', text: 'Text Page' },
    { to: 'trigger', text: 'Trigger Page' },
    { to: 'upload', text: 'Upload Page' },
  ];

  return (
    <>
      <p>
        This is a UI playground meant for demonstration purposes.
        <br />
        TBD = to be developed
      </p>
      <h2>UI Testing</h2>
      {pages.map((page, pageIndex) => (
        <div key={pageIndex}>
          <Link to={page.to} data-cy={page.to}>
            {page.text}
          </Link>
        </div>
      ))}
      <h2>API Testing</h2>
      <div>
        <Link to="todo-list" data-cy="todo-list">
          To-Do List
        </Link>
      </div>
    </>
  );
}

export default PlaygroundPage;
