function AboutPage() {
  return (
    <>
      {' '}
      <h1>About Automated Quality Assurance (AQA)</h1>
      <p>
        Automation Quality Assurance (AQA) is about creating easy to use
        end-to-end (E2E) automation frameworks.
        <br /> Helping QA departments start on the correct footing in order to
        be successful.
      </p>
      <p></p>
      <p>
        We put the <b>quality</b> in quality assurance!
      </p>
    </>
  );
}

export default AboutPage;
