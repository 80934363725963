function HomePage() {
  return (
    <>
      <h1>Welcome to Automated Quality Assurance (AQA)</h1>
      <p>
        Site is under construction...
        <br />
        New to web development, but expert in QA and automation!
      </p>
    </>
  );
}

export default HomePage;
